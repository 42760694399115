import React, { useState, useEffect } from 'react';
import { Fade } from "react-awesome-reveal";
import "../styles/Carousel.scss";
import Whats from './Whats';
import img1 from "../images/carousel/1.png"
import img2 from "../images/carousel/2.png"
import img3 from "../images/carousel/3.png"
import img4 from "../images/carousel/4.png"
import img5 from "../images/carousel/5.png"
import img6 from "../images/carousel/6.png"

const Carousel = () => {

    const [anos, setAnos] = useState(0);
    const [reparaciones, setReparaciones] = useState(0);
    const [clientes, setClientes] = useState(0);

    let anosMax=10
    let reparacionesMax=800
    let clientesMax=400

    useEffect(() => {
      const intervalId1 = setInterval(() => {
        if (anos < anosMax) {
          setAnos(anos + 1);
        } else {
          clearInterval(intervalId1);
        }
      }, 10);

      return () => clearInterval(intervalId1);
    }, [anos]);

    useEffect(() => {
        const intervalId2 = setInterval(() => {
          if (reparaciones < reparacionesMax) {
            setReparaciones(reparaciones + 10);
          } else {
            clearInterval(intervalId2);
          }
        }, 5);
  
        return () => clearInterval(intervalId2);
      }, [reparaciones]);

      useEffect(() => {
        const intervalId3 = setInterval(() => {
          if (clientes < clientesMax) {
            setClientes(clientes + 5);
          } else {
            clearInterval(intervalId3);
          }
        }, 10);
  
        return () => clearInterval(intervalId3);
      }, [clientes]);

  return (
    <div className="mb-5 carouselContainer" id="inicio">
        <div className="carouselText">
            <Fade><h1 className="title1 mt-3"><b>Vidriart - Ventanas, Canceles y Proyectos en Vidrio y Aluminio</b></h1></Fade>
            <h2 className="mb-3 title2">"Transformamos tus espacios con la mejor calidad y diseño. Ofrecemos soluciones completas para proyectos residenciales y comerciales."</h2>
            <span className="firstText">En Vidriart, somos especialistas en transformar espacios con soluciones de vidrio y aluminio de alta calidad. Ubicados en San Nicolás de los Garza, N.L., ofrecemos una amplia gama de servicios, incluyendo la fabricación e instalación de ventanas, canceles de cristal templado, barandales, y puertas de aluminio.<br/><br/>Nos dedicamos a proyectos residenciales y comerciales, garantizando seguridad, diseño moderno y funcionalidad. Nuestro equipo se enfoca en cada detalle para asegurar que tus espacios no solo sean seguros, sino también estéticamente impresionantes. Contáctanos para darle a tu hogar o negocio la elegancia que merece.</span>
            <Fade><div className="container containerDatosMain mt-4 mb-3">
                <div className="containerDatos">
                    <span className="text-center contadorNum fw-bold" id="anosExp">{"+"+anos}</span><br/>
                    <span className="contadorNumText fw-bold">Años de experiencia</span>
                </div>
                <div className="containerDatos">
                    <span className="contadorNum fw-bold" id="reparaciones">{"+"+reparaciones}</span><br/>
                    <span className="contadorNumText fw-bold">Instalaciones realizadas</span>
                </div>
                <div className="containerDatos">
                    <span className="contadorNum fw-bold" id="clientes">{"+"+clientes}</span><br/>
                    <span className="contadorNumText fw-bold">Clientes satisfechos</span>
                </div>
            </div></Fade>
            
            <Fade cascade="true" direction="left"><div className="mt-4 container containerBtn1">
               <Whats classStyle="col-sm-6 mb-2 fw-bold btn btnCarousel" phoneNumber="5218116023326" message="Hola, quiero más información acerca de sus servicios." text="CONTÁCTANOS" />
            </div></Fade>
        </div>
               
        <div className="carouselImages">
            <div id="carouselExampleAutoplaying" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-inner imgBox">
                    <div className="carousel-item active">
                        <center><img src={img1} className="d-block w-100 " alt=""/></center>
                    </div> 
                    <div className="carousel-item">
                        <center><img src={img2} className="d-block w-100 " alt=""/></center>
                    </div> 
                    <div className="carousel-item">
                        <center><img src={img3} className="d-block w-100 " alt=""/></center>
                    </div> 
                    <div className="carousel-item">
                        <center><img src={img4} className="d-block w-100 " alt=""/></center>
                    </div> 
                    <div className="carousel-item">
                        <center><img src={img5} className="d-block w-100 " alt=""/></center>
                    </div> 
                    <div className="carousel-item">
                        <center><img src={img6} className="d-block w-100 " alt=""/></center>
                    </div> 
                </div>
            </div> 
        </div>
    </div>
  );
};

export default Carousel;
