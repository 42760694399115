import React from "react";
import { Fade } from "react-awesome-reveal";
import "../styles/Footer.scss";
import facebook from "../images/redes/Caralibro.svg"
import Instagram from "../images/redes/Instagram.svg"
import Linkedin from "../images/redes/Linkedin.svg"
import Tiktok from "../images/redes/Tik tok.svg"
import EsX from "../images/redes/X.svg"
import appStore from "../images/App.svg"
import logo from "../images/logo.png"

const Footer = () => {

  return (
    <footer id="contacto">
        <div className="containerFooter">

            <div className="column1">
                <a href="./index.php"><img src={logo} alt="Logo Coorporativo Salcedo"/></a>
                <div className="containerTextColumn1">
                    <p>En Vidriart, nos especializamos en la instalación de productos en vidrio, aluminio y cristal templado. Atendemos proyectos residenciales y comerciales, garantizando seguridad y un diseño moderno. Ubicados en San Nicolás de los Garza, N.L.</p>
                </div>
            </div>

            <div className="column2">
                <div>
                    <p>NOSOTROS</p>
                    <div className="textColumn2">
                        <a href="#inicio"><span>Conócenos</span></a><br/>
                        <a href="#porqueE"><span>¿Porque elegirnos?</span></a><br/>
                    </div>
                </div><br/>
                <div>
                    <p>DATOS DE CONTACTO</p>
                    <div className="textColumn2">
                        <a href="mailto:contacto@vidriart.com"><span>contacto@vidriart.com</span></a><br/>
                        <a href="tel:+528116023326"><span>811 602 3326</span></a>
                    </div>
                </div>
            </div>
            
            <div className="column3">
                <div>
                    <p>SERVICIOS</p>
                    <div className="textColumn3">
                        <a href="#servicio1"><span>Diseño de Oficinas</span></a><br/>
                        <a href="#servicio2"><span>Proyectos Comerciales</span></a><br/><br/>
                        <span className="ubicacionTitle">UBICACIÓN</span><br/>
                        <a href="https://maps.app.goo.gl/fMTsiDvZFZepgpuM8" ><span id="ubicacion">C. Gonzalitos Ote 536-A, San Nicolás de los Garza, Nuevo León</span></a><br/>
                        
                    </div>
                </div>
            </div>

            <div className="column4">
                <div>
                    <p>APPS</p>
                    <div>
                        <span>Proximamente</span>
                    </div>
                    <div className="containerImgApp">
                        <img src={appStore} alt=""/>
                    </div>
                </div>
                
                <div className="containerRedes">
                    <Fade cascade="true" direction="left"><a href="https://www.facebook.com/profile.php?id=61556230733194"><img src={facebook} alt="Facebook Vidriart"/></a></Fade>
                    <Fade cascade="true" direction="left"><a href="https://www.instagram.com/_vidriart/"><img src={Instagram} alt="Instagram Vidriart"/></a></Fade>
                </div>
            </div>

        </div>
</footer>
  );
};

export default Footer;

